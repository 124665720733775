<template>
  <div class="text-primary font-weight-bolder">
    <b-button
      size="sm"
      variant="primary"
      class="font-weight-bolder"
      :to="handleRouter(quote)"
    >
      #{{ quote.folio }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
  },

  methods: {
    handleRouter(quote) {
      // customer routes
      if (this.currentUser.role_name === 'customer'
          && quote.quote_status === 'bid_approved'
          && quote.payment_status === 'payment_approved'
      ) {
        return {
          name: 'purchaseOrder',
          params: { quoteId: quote.id },
        }
      }
      // admin routes
      if (this.currentUser.role_name === 'admin') {
        if (
          (quote.quote_status === 'bid_approved'
            || quote.quote_status === 'buyout')
          && quote.payment_status === 'payment_approved'
        ) {
          return {
            name: 'purchaseOrder',
            params: { quoteId: quote.id },
          }
        }
        if (quote.quote_status === 'bidding') {
          return {
            name: 'firstRound',
            params: { quoteId: quote.id },
            query: { branchId: this.$route.params.id },
          }
        }
      }
      // auctioneer routes
      if (
        this.currentUser.role_name === 'auctioneer'
        || this.currentUser.role_name === 'supplier_admin'
      ) {
        if (
          (quote.quote_status === 'bid_approved'
            || quote.quote_status === 'buyout')
          && quote.payment_status === 'payment_approved'
        ) {
          return {
            name: 'purchaseOrder',
            params: { quoteId: quote.id },
          }
        }
        if (quote.quote_status === 'bidding') {
          return {
            name: 'firstRound',
            params: { quoteId: quote.id },
            query: { branchId: this.$route.params.id },
          }
        }
      }
      if (
        quote.is_published
        && (quote.quote_status === 'pending'
          || quote.quote_status === 'awaiting_customer_response')
      ) {
        return { name: 'prequote', params: { quoteId: quote.id } }
      }
      if (quote.quote_status === 'pending') {
        return {
          name: 'addProductsToQuote',
          params: { quoteId: quote.id },
        }
      }
      if (quote.quote_status === 'buyout') {
        return {
          name: 'checkout',
          params: { quoteId: quote.id },
        }
      }
      if (quote.quote_status === 'bidding') {
        return {
          name: 'auctionInProgress',
          params: { quoteId: quote.id },
        }
      }
      if (quote.quote_status === 'awaiting_bid_approval') {
        return {
          name: 'clientQuote',
          params: { id: quote.id },
        }
      }
      if (quote.quote_status === 'bid_approved') {
        return {
          name: 'checkout',
          params: { quoteId: quote.id },
        }
      }
      return {
        name: 'checkout',
        params: { quoteId: quote.id },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding: 3px !important;
}
</style>
